@import "../scss/variables";

.masthead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 16rem 0;
  background: linear-gradient(to bottom, #{fade-out($black, 0.7)} 0%, #{fade-out($black, 0.3)} 75%, $black 100%),
    url("../../img/bg-masthead.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  h1 {
    min-height: 13rem;
    font-family: "Varela Round";
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 2.4rem;
    letter-spacing: 0.6rem;
    padding-bottom: 30px;
    color: rgba(255, 250, 240, 0.8);
    text-shadow: 0 0 15px rgba(255, 200, 100, 0.4);
    // background: -webkit-linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3));
    // -webkit-text-fill-color: transparent;
    // -webkit-background-clip: text;
  }
  h2 {
    max-width: 20rem;
    font-size: 1rem;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
  @media (min-width: 992px) {
    height: 100vh;
    padding: 0;
    h1 {
      padding-top: 9rem;
      min-height: 34rem;
      font-size: 4rem;
      line-height: 6rem;
      letter-spacing: 1.2rem;
    }
    h2 {
      max-width: 30rem;
      font-size: 1.25rem;
    }
  }
}
