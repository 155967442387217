@import "variables.scss";
@import "mixins.scss";
@import "../about/about";
@import "../header/header";
@import "../navbar/navbar";
@import "../projects/projects";
@import "../signup/signup.scss";
@import "../contact/contact.scss";
@import "buttons.scss";

body {
  letter-spacing: 0.0625em;
  background: url("../../img/bg-masthead.jpg");
  background-color: $gray-900;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: $primary;
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($primary, 20%);
  }
}

.bg-black {
  background-color: $black !important;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

footer {
  padding: 5rem 0;
}
