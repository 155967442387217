.projects-section {
  padding: 6rem 0;
  .featured-text {
    padding: 2rem;
    @media (min-width: 992px) {
      padding: 0 0 0 2rem;
      border-left: 0.5rem solid $primary;
    }
  }
  .projects-title {
    min-height: 80px;
  }
  .project-text {
    padding: 3rem;
    font-size: 90%;
    @media (min-width: 992px) {
      padding: 5rem;
      hr {
        border-color: $primary;
        border-width: 0.25rem;
        width: 30%;
      }
    }
  }
  .project-image {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .advantage {
    @media (min-width: 992px) {
      padding-top: 4rem;
    }
    p {
      font-family: "Varela Round";
      font-size: 1.2rem;
      margin-bottom: 1rem;
      padding-top: 3rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  h2 {
    text-align: center;
  }
  p {
    text-align: center;
  }
}
