@import "../scss/variables";

.about-section {
  padding-top: 6rem;
  padding-bottom: 2rem;
  background: linear-gradient(to bottom, $black 0%, #{fade-out($black, 0.1)} 75%, #{fade-out($black, 0.2)} 100%);
  p {
    margin-bottom: 5rem;
    // min-height: 180px;
  }
}
