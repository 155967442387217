@import "../scss/variables";

#mainNav {
  min-height: 56px;
  background-color: rgba(0, 0, 0, 0.9);
  .navbar-toggler {
    font-size: 100%;
    padding: 0.75rem;
    color: $primary;
    border: 1px solid rgb(40, 70, 60);
    &:focus {
      outline: none;
    }
  }
  .navbar-brand {
    color: rgb(222, 222, 222);
    font-weight: 700;
    padding: 0.9rem 0;
  }
  .navbar-nav {
    .nav-item {
      &:hover {
        color: fade(white, 80%);
        outline: none;
        background-color: rgba(255, 240, 200, 0.1);
      }
      &:active,
      &:focus {
        outline: none;
        background-color: transparent;
      }
    }
  }
  .show {
    .nav-link {
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-family: "Varela Round";
      font-size: 1.2rem;
      line-height: 3rem;
      color: rgba(255, 250, 240, 0.8);
      text-shadow: 0 0 15px rgba(255, 200, 100, 0.4);
      // background: -webkit-linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3));
      // -webkit-text-fill-color: transparent;
      // -webkit-background-clip: text;
      padding: 1rem 0 1rem;
    }
  }
  @media (min-width: 992px) {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    .navbar-brand {
      padding: 0.5rem 0;
      color: fade-out($white, 0.5);
    }
    .nav-link {
      transition: none;
      padding: 2rem 1.5rem;
      color: fade-out($white, 0.5);
      &:hover {
        color: fade-out($white, 0.25);
      }
      &:active {
        color: white;
      }
    }
    &.navbar-shrink {
      background-color: rgba(13, 13, 13, 0.9);
      .navbar-brand {
        color: rgb(122, 122, 122);
      }
      .nav-link {
        color: rgb(122, 122, 122);
        padding: 1.5rem 1.5rem 1.25rem;
        border-bottom: 0.25rem solid transparent;
        &:hover {
          color: $primary;
        }
        &:active {
          color: darken($primary, 15%);
        }
        &.active {
          color: $primary;
          outline: none;
          border-bottom: 0.3rem solid $primary;
        }
      }
    }
  }
}
